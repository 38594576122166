<template>
  <div class="caseMain-wrap70">
    <div class="head">
      <header2></header2>
    </div>
    <div class="caseMain-wrap70content">
      <div class="main-wrap">
        <div class="content">
          <div class="regHd">
            <div class="s1"></div>
            个人信息注册
          </div>
          <div class="tabs">
            <div class="li-tab" @click="cur=0" :class="{active:cur==0}">
              <img src="../../assets/images/login-student.png"/>
              <div class="t1">学生</div>
            </div>
          </div>
          <div class="Tab" v-show="cur==0">
            <el-form
                ref="ruleForm"
                :model="ruleForm"
                status-icon
                :rules="rules"
                label-width="100px"
            >
              <div class="reg-warp">
                <div class="personal">
                  <div class="p-lf">个人信息</div>
                  <div class="p-rg">
                    <el-form-item label="姓名" prop="stuName">
                      <el-input v-model="ruleForm.stuName" style="width: 450px"></el-input>
                      <span class="test">姓名作为实名制信息，不能修改，请认真输入</span>
                    </el-form-item>
                    <el-form-item label="昵称" prop="nickName">
                      <el-input v-model="ruleForm.nickName" style="width: 450px"></el-input>
                    </el-form-item>
                    <el-form-item label="地区" placeholder="请选择地区" prop="area">
                      <el-cascader
                          style="width: 450px"
                          ref="getCitySelName"
                          v-model="ruleForm.area"
                          :options="findProviceCityData"
                          :props="defaultData"
                          @change="changefn"
                      ></el-cascader>
                    </el-form-item>
                    <el-form-item label="头像" prop="">
                      <div class="portraitList">
                        <img v-for="(item,index) in portraitList" :key="index"
                             :class="{'onClickStyle':clickFlag == index}" @click="onClickItem(item,index)"
                             :src="item.url" alt=""/>
                      </div>
                    </el-form-item>
                    <el-form-item label="性别" prop="">
                      <el-select v-model="ruleForm.gender" placeholder="请选择" style="width: 450px">
                        <el-option
                            v-for="item in stateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="生日" prop="">
                      <el-date-picker v-model="ruleForm.birthday" value-format=yyyy-MM-dd type="date"
                                      placeholder="请选择生日" style="width: 450px">
                      </el-date-picker>
                    </el-form-item>

                    <el-form-item label="学校" prop="schoolName">
                      <el-input v-model="ruleForm.schoolName" style="width: 450px"></el-input>
                    </el-form-item>
                    <el-form-item label="入学年份" prop="enrollmentYear">
                      <el-select v-model.trim="ruleForm.enrollmentYear" @change="GradeChang" clearable placeholder="年份选择">
                        <el-option
                            v-for="item in findScreenGradeData"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>

                  </div>
                </div>
                <div class="account">
                  <div class="p-lf">账户信息</div>
                  <div class="p-rg">
                    <el-form-item label="手机号" prop="mobileNumber">
                      <el-input v-model="ruleForm.mobileNumber" style="width: 450px"></el-input>
                      <span class="test">手机号作为用户身份ID，只能输入一次且不能修改，请认真输入</span>
                    </el-form-item>
                    <el-form-item label="验证码" prop="verifCode">
                      <el-input v-model="ruleForm.verifCode" style="width: 327px;margin-right: 10px"></el-input>
                      <el-button class="login-getcode" type="primary" @click="getCode()" :class="{'disabled-style':getCodeBtnDisable}" :disabled="getCodeBtnDisable">{{codeBtnWord}}</el-button>
                    </el-form-item>
                    <el-form-item label="设置密码" prop="pass">
                      <el-input v-model="ruleForm.pass" style="width: 450px"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="passWord">
                      <el-input v-model="ruleForm.passWord" style="width: 450px"></el-input>
                    </el-form-item>
                    <el-form-item label="邀请码" prop="">
                      <el-input v-model="ruleForm.invitationCode" style="width: 450px"></el-input>
                      <span class="test">请填写老师给予的邀请码，如果没有，则无需填写</span>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="btn-warp">
                <el-button type="primary" @click="regForm('ruleForm')">提 交</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="注册提示" :visible.sync="successDialog" :close-on-click-modal="false" width="30%">
      <div class="dialogHd"
           style="text-align: center;height: 50px; line-height: 50px;font-size: 20px;font-weight: bold">
        <el-icon color="#359552" style="vertical-align: middle" :size="50">
          <circle-check-filled/>
        </el-icon>
        注册成功
      </div>
      <div style="line-height: 30px;padding-top:50px;padding-bottom: 50px;box-sizing: border-box; text-align: center">
        恭喜！{{ ruleForm.stuName }}同学，您已经成功注册学历案！<br>
        现在就登录吧，开始您的自主学习之旅！
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="regObj()" type="primary">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>


</template>


<script>
import header2 from '@/components/layout/header2.vue'
import {stuRegObj,findProviceCityObj,findGradeObj,sendPhoneCodeObj} from '@/api/login'

export default {
  name: '',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.passWord !== '') {
          this.$refs.ruleForm.validateField('passWord');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      waitTime:61, // 获取验证码按钮失效时间

      findProviceCityData:[],
      findScreenGradeData:[],
      defaultData:{   //联级选择
        value: 'value',
        label: 'label',
        children: 'children'
      },
      successDialog: false,
      AuthorstuName: '',
      time: 5,
      btntxt: "发送验证码",
      clickFlag: -1,
      cur: 0,//默认选中第一个tab

      stateList: [
        {
          name: '男',
          id: 1
        },
        {
          name: '女',
          id: 2
        }
      ],
      stuReg: {
        stuName: "",
        nickName: "",
        profilePhotoPath: "",
        gender: '',
        birthday: '',
        area: '',
        area1: '',
        schoolName: '',
        gradeName: '',
        mobileNumber: '',
        verifCode: '',
        pass: '',
        passWord: '',
        invitationCode: '',
      },
      portraitList: [
        {id: 1, url: require('../../assets/images/m1.png')},
        {id: 2, url: require('../../assets/images/m2.png')},
        {id: 3, url: require('../../assets/images/m3.png')},
        {id: 4, url: require('../../assets/images/m4.png')},
        {id: 5, url: require('../../assets/images/m5.png')},
      ],
      value: [],
      ruleForm: {
        stuName: "",
        nickName: "",
        profilePhotoPath: "",
        gender: '',
        birthday: '',
        area: '',
        schoolName: '',
        gradeName: '',
        mobileNumber: '',
        verifCode: '',
        passWord: '',
        pass: '',
        invitationCode: '',
        enrollmentYear:'',
      },
      rules: {
        stuName: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
          {
            validator: function(rule, value, callback) {
              //校验中文的正则：/^[\u4e00-\u9fa5]{0,}$/
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入中文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          },
          {
            min: 1,
            max: 30,
            message: '用户名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        nickName: [
          {
            required: true,
            message: '请输入昵称',
            trigger: 'blur',
          },
        ],
        schoolName: [
          {
            required: true,
            message: '请输入学校',
            trigger: 'blur',
          },
        ],
        gradeId: [
          {required: true, message: '请选择年级', trigger: 'change'},
        ],
        verifCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
        ],
        area: [
          {required: true, message: '请选择地区', trigger: 'change'},
        ],
        enrollmentYear: [
          {required: true, message: '请选择入学年份', trigger: 'change'},
        ],
        profilePhotoPath: [
          {
            required: true,
            message: '请选择用户头像',
            trigger: 'blur',
          },
        ],
        pass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        passWord: [
          {required: true, message: '请确认密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
          {validator: validatePass2, trigger: 'blur', required: true}
        ],
        mobileNumber: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'blur',
          },
          {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号'},
        ],


        code: [
          {
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 4,
            message: '验证码长度必须为4个字符',
            trigger: 'blur',
          },
        ],
      },
      parentruleForm: {
        stuName: "",
        nickName: "",
        profilePhotoPath: "",
        gender: '',
        birthday: '',
        area: '',
        areA: '',
        schoolName: '',
        gradeId: '',
        mobileNumber: '',
        verifCode: '',
        passWord: '',
        pass: '',
        invitationCode: '',
      },
      parentrules: {
        stuName: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '用户名长度必须在 1 到 30个字符',
            trigger: 'blur',
          },
        ],
        nickName: [
          {
            required: true,
            message: '请输入昵称',
            trigger: 'blur',
          },
        ],
        profilePhotoPath: [
          {
            required: true,
            message: '请选择用户头像',
            trigger: 'blur',
          },
        ],
        pass: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        passWord: [
          {required: true, message: '请确认密码', trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'},
          {validator: validatePass2, trigger: 'blur', required: true}
        ],
        mobileNumber: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'blur',
          },
          {pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])d{8}$/, message: '请输入正确是手机号'},
        ],


        code: [
          {
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 4,
            message: '验证码长度必须为4个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.findProviceCity()
    this.findScreenGrade()
    this.portraitList.forEach((ele) => {
      if (this.ruleForm.profilePhotoPath == ele.id){
        this.picImgSrc = ele
      }
    })

  },
  computed: {
    // 用于校验手机号码格式是否正确
    phoneNumberStyle(){
      let reg = /^1[3456789]\d{9}$/
      if(!reg.test(this.ruleForm.mobileNumber)){
        return false
      }
      return true
    },
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable:{
      get(){
        if(this.waitTime == 61){
          if(this.ruleForm.mobileNumber){
            return false
          }
          return true
        }
        return true
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set(){}
    }
  },
  mounted() {
    this.AuthorstuName = window.localStorage.getItem("AuthorstuName");

  },
  methods: {
    getCode(){
      if(this.phoneNumberStyle){
        // 调用获取短信验证码接口
        let obj = {
          mobile:this.ruleForm.mobileNumber,
          type:1// 学生
        }
        sendPhoneCodeObj(obj).then((res) => { // eslint-disable-line no-unused-vars
          if (res.code == 200) {
            this.$message({
              message: '验证码已发送成功',
              type: 'success',
              center:true
            })
            console.log(res,'发送手机验证码')
            this.disabled = true;
            this.codeTitle = "请稍候...";
            setTimeout(() => {
              this.doLoop(60)
            }, 10)
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
        // 因为下面用到了定时器，需要保存this指向
        let that = this
        that.waitTime--
        that.getCodeBtnDisable = true
        this.codeBtnWord = `${this.waitTime}s 后重新获取`
        let timer = setInterval(function(){
          if(that.waitTime>1){
            that.waitTime--
            that.codeBtnWord = `${that.waitTime}s 后重新获取`
          }else{
            clearInterval(timer)
            that.codeBtnWord = '获取验证码'
            that.getCodeBtnDisable = false
            that.waitTime = 61
          }
        },1000)
      }
    },

    xbChange($event) {
      this.ruleForm.gender = $event
    },
    ProviceCityChange() {
      this.vals=findProviceCityObj(this.selectedOptions, this.findProviceCityData);
      this.vals.map(item=>{
        this.name = item.name;
        this.id = item.id
        console.log(this.id,'9900');
        console.log(this.name,'9911');
      })

    },
    changefn(value) {
      this.ruleForm.areA=JSON.stringify(value)
      this.ruleForm.area=value

      console.log(this.ruleForm.area,'这是选中的地区')
      //console.log(JSON.stringify(value),'3333这是选中的地区')
    },
    //点击改变文字颜色
    onClickItem(item, index) {
      this.clickFlag = index;
      this.ruleForm.profilePhotoPath = item.id;
      console.log(item.id, '获取头像ID')
    },
    GradeChang($event){
      this.ruleForm.gradeId = $event;
    },
    // 请求获取短信接口
    sendcode() {
      this.time = 30;
      this.timer();
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = "倒计时" + this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "发送验证码";
        this.disabled = false;
      }
    },
    findProviceCity() {

      findProviceCityObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          this.findProviceCityData = res.data
          console.log(res)
        }
      })

    },
    findGrade() {



    },
    findScreenGrade() {

      findGradeObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          this.findScreenGradeData = res.data
          console.log(res)
        }
      })

    },
    regForm(formName) {
      localStorage.setItem('AuthorstuName', this.ruleForm.stuName)
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            stuName: this.ruleForm.stuName,
            nickName: this.ruleForm.nickName,
            profilePhotoPath: this.ruleForm.profilePhotoPath,
            gender: this.ruleForm.gender,
            birthday: this.ruleForm.birthday,
            area:this.ruleForm.areA,
            schoolName: this.ruleForm.schoolName,
            mobileNumber: this.ruleForm.mobileNumber,
            verifCode: this.ruleForm.verifCode,
            passWord: this.ruleForm.passWord,
            invitationCode: this.ruleForm.invitationCode,
            enrollmentYear:this.ruleForm.enrollmentYear,
          }
          stuRegObj(params).then((res) => { // eslint-disable-line no-unused-vars
            if (res.code == 200) {
              this.successDialog = true
              console.log(res)
            } else {
              this.message = res.message
              this.$message.warning(this.message);
            }
          })
        }
      })

    },
    regObj() {
      localStorage.removeItem("AuthorstuName");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("status");
      localStorage.removeItem("Authorization");
      this.successDialog = false
      this.$router.replace({
        path: "/",
        meta: {allowBack: false}
      });
    },

    parentregForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            // stuName:this.ruleForm.stuName,
            // nickName:this.ruleForm.nickName,
            // profilePhotoPath:this.ruleForm.profilePhotoPath,
            // gender:this.ruleForm.gender,
            // birthday:this.ruleForm.birthday,
            ...this.parentruleForm,
          }
          stuRegObj(params).then((res) => { // eslint-disable-line no-unused-vars
            if (res.code == 200) {

              this.message = res.message
              this.$message.success(this.message);
              this.$router.push({
                path: "/",
              });
              console.log(res)
            } else if (res.code == 204) {
              this.message = res.message
              this.$message.warning(this.message);
            }
          })
        }
      })

    },


  },
  components: {
    // 图标
    header2,

    //注册图标组件名称
  }
}
</script>
<style lang="scss" scoped>
.caseMain-wrap70 {
  width: 100%;
  display: flex;
  background-size: 100% 100%;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: hidden;
.login-getcode {
  border: none;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  box-sizing: border-box;
  padding: 6px 10px;
  height: 36px;
  background-color: #008DF0;
}
  .main-wrap .content {
    margin: 1.25rem 0 1.25rem 0;
    padding: 0 1.25rem;
    box-sizing: border-box;
    display: block;
    justify-content: space-between;
    width: 98%;
    background: #FFFFFF;
    box-shadow: 0 0.1875rem 0.375rem rgb(0 0 0 / 16%);
    opacity: 0.9;
  }
  .el-button.disabled-style {
    background-color: #EEEEEE;
    color: #CCCCCC;
  }
  .head {
    width: 100%;
    height: 70px;
    position: fixed;
    z-index: 0;
  }

  .caseMain-wrap70content {
    width: 100%;
    position: fixed;
    /*固定定位*/
    top: 70px;
    bottom: 0;
    overflow-y: auto;


  }
}

.regHd {
  width: 100%;
  height: 36px;
  line-height: 26px;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #238bff;
  margin-top: 20px;
  display: flex;

  .s1 {
    display: block;
    width: 4px;
    height: 26px;
    margin-right: 10px;
    background-color: #238bff;
  }
}

.tabs {
  width: 100%;
  height: 90px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  .active {
    border-radius: 50px;
    border: 1px solid #F77134;
    background: #FFFCB2;
    color: #ff6820;
  }

  .li-tab {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border: 1px solid #bbb;
    margin: 0px 25px 0 25px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    cursor: pointer;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;
    }

    .t1 {
      text-align: center;
    }
  }


}

.Tab {
  display: block;
  width: 100%;
  margin-top: 10px;

  .reg-warp {
    width: 90%;
    margin: auto;
    padding: 0 50px;
    box-sizing: border-box;
    overflow-y: auto;

    .test {
      margin-left: 30px;
      color: #b1bcd6;
    }

    .personal {
      width: 100%;
      display: flex;
      margin-top: 20px;

      .p-lf {
        height: 500px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 500px;

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;

          }
        }

        .onClickStyle {
          width: 50px;
          height: 50px;
          background-color: #238bff;
          border: 2px solid #238bff;
          border-radius: 50px;

          img {

          }
        }

        //background-color: #238bff;
      }
    }

    .account {
      width: 100%;
      display: flex;
      margin-top: 40px;

      .p-lf {
        height: 320px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 320px;

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;
          }
        }

        //background-color: #238bff;
      }
    }

    .parentpersonal {
      width: 100%;
      display: flex;
      margin-top: 20px;

      .p-lf {
        height: 190px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 190px;

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;
          }
        }

        //background-color: #238bff;
      }
    }

    .parentaccount {
      width: 100%;
      display: flex;
      margin-top: 40px;

      .p-lf {
        height: 230px;
        display: flex;
        padding-right: 30px;
        align-items: center;
        text-align: center;
        border-right: 1px dashed #d5dee9;

        width: 20px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
      }

      .p-rg {
        width: 1200px;
        height: 230px;

        .tip {
          width: 96%;
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          margin: auto auto 10px auto;
          background: #FFECD8;
          border: 1px solid #FF9B36;
          opacity: 1;
          color: #D50007;
        }

        .portraitList {
          width: 100%;

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;
          }
        }

        //background-color: #238bff;
      }
    }
  }

  .btn-warp {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  ::v-deep.dialogHd {
    text-align: center;
  }
}
</style>
